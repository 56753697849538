// https://favicon.io/favicon-converter/

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
//import Button from 'react-bootstrap/Button';
//import Form from 'react-bootstrap/Form';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import logoA2mj from './assets/a2mj-logo.png'
import logoPsiu from './assets/psiu-logo-menor.png'
import logoPsiuMaior from './assets/psiu-platform.png'

const AppHeader = () => {
  return (
    <Container fluid>
      <Row>
        <Col lg={2} sm={3} xs={12}>
          <Image src={logoA2mj} />
        </Col>
        <Col lg={10} sm={9} xs={0} style={{textAlign: "left"}} >
          {/* As classes css 'd-none d-lg-block' escondem a coluna se o tamanho for diferente de lg 
            Mais em https://getbootstrap.com/docs/4.1/utilities/display/#hiding-elements */}
          <div className="d-none d-lg-block"><br /></div>          

          <h1>Inovação e Integração para o Futuro</h1>
          <h3>Solucionamos seus desafios em TI com as melhores tecnologias e plataformas digitais</h3>
        </Col>
      </Row>
    </Container>
  )
}

const AppNavBar = () => {
  return (
    <Navbar expand="lg" bg="primary" data-bs-theme="dark">
      <Container fluid>
        <Navbar.Brand href="#">A2MJ Tec</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            {/*}
            <Nav.Link href="#action1">Home</Nav.Link>
            <Nav.Link href="#action2">Link</Nav.Link>
            <NavDropdown title="Link" id="navbarScrollingDropdown">
              <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">
                Something else here
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="#" disabled>
              Link
            </Nav.Link>
            {*/}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}


function App() {
  return (
    <>                
      <AppHeader />
      <AppNavBar />    
      <Container>          
        <Row style={{marginTop: "10px"}} >
          <Col lg={8} xm={6}>
            <h2 id="home">Sobre nós</h2>
            <p>Nossa missão é trazer inovação e integração de soluções de tecnologia da informação e plataformas digitais, com o foco nas empresas do ramo de bares e restaurantes.</p>
            <p><strong>Visão:</strong> Ser referência no mercado de TI pela excelência e inovação.</p>
            <p><strong>Valores:</strong> Inovação, Qualidade, Comprometimento, Ética.</p>          
          </Col>
          <Col lg={4} xm={6} className="d-flex justify-content-center">
            <Image src={logoPsiu} />
          </Col>
        </Row>
      </Container>
    </>
  );  
}

export default App;
